/* eslint-disable default-case */
/* eslint-disable react/prop-types */
import { useEvent } from 'lib/Hooks/useEvent';
import React, {
  useEffect,
  useImperativeHandle,
  useRef,
  forwardRef,
  useState,
  useCallback,
} from 'react';

const MESSAGE_DELIMITER = 'xPYMx';

/**
 * @param {string} message
 * @param {Function} onReadyChild
 */
export function onNavigateMessage(message, onReadyChild) {
  let data = null;

  try {
    data = JSON.parse(message);
  } catch (error) {
    console.error('onNavigateMessageError: ', error);
  }

  if (data?.readyChild) {
    setTimeout(onReadyChild, 1000);
  }

  if (data?.setAnchor) {
    window.location.href = `#${data.setAnchor}`;
  }
}

/**
 * @param {object} event
 * @returns {string[]}
 */
export function parseEvent(event) {
  if (typeof event?.data !== 'string') {
    return [];
  }

  const splitted = event.data.split(MESSAGE_DELIMITER);
  if (splitted.length !== 4) {
    return [];
  }

  return splitted.slice(2);
}

/**
 * @param {string} url
 * @returns {boolean}
 */
export function isSafe(url) {
  // Copied from pym
  const SAFE_URL_PATTERN = /^(?:(?:https?|mailto|ftp):|[^&:/?#]*(?:[/?#]|$))/gi;
  return url.match(SAFE_URL_PATTERN);
}

/**
 * @param {string} message
 * @returns {void}
 */
function onNavigateToMessage(message) {
  if (isSafe(message)) {
    window.location.href = message;
  }
}

/**
 * PymFrame is a component that behaves like a Pym parent, but is implemented in react.
 *
 * While this component can be used as a performant alternative to loading a pym script,
 * it is not necessarily a drop-in replacement because only currently needed functionality
 * is implemented.
 *
 * @param {object} props
 * @param {string} props.url iframe content url
 * @param {string} props.title accessible title for the iframe
 * @param {string} [props.initialHeight] height attribute value for the iframe
 * @param {string} [props.srcDoc] use this prop to inline the iframe content.  Note that
 * the location.href will appear as "about:srcdoc" to the child
 * @param {(messageType: string, messageData: string) => void} [props.onChildMessage] event
 * callback for any messages that the pym child sends.
 */
export const PymFrame = forwardRef(({
  title, url, initialHeight, onChildMessage, srcDoc,
}, ref) => {
  const iframeRef = useRef(null);
  const [height, setHeight] = useState(initialHeight ?? '100%');

  const onMessageEvent = useEvent(onChildMessage);

  const sendMessage = useCallback((messageType, data) => {
    const message = ['pym', 0, messageType, data].join(MESSAGE_DELIMITER);
    const pymOrigin = window.pym?.origin || '*';
    iframeRef.current?.contentWindow.postMessage(message, pymOrigin);
  }, []);

  useImperativeHandle(ref, () => ({ sendMessage }), [sendMessage]);

  useEffect(() => {
    /**
     */
    function sendHash() {
      const hash = window.location.hash.substring(1);
      sendMessage('navigateAnchor', `{ "element": "${hash}" }`);
    }

    /**
     * @param {string} event
     * @returns {void}
     */
    function onUnparsedMessage(event) {
      if (event.origin !== 'null' && event.source !== iframeRef.current?.contentWindow) {
        return;
      }

      const [messageType, messageData] = parseEvent(event);

      switch (messageType) {
        case 'height':
          setHeight(`${messageData}px`);
          break;
        case 'navigateMessage':
          onNavigateMessage(messageData, sendHash);
          break;
        case 'navigateTo':
          onNavigateToMessage(messageData);
          break;
      }

      if (messageType) {
        onMessageEvent?.(messageType, messageData);
      } else {
        console.warn('Pym: Message could not be parsed:', event.data);
      }
    }

    window.addEventListener('hashchange', sendHash);
    window.addEventListener('message', onUnparsedMessage);

    sendMessage('width', iframeRef.current?.offsetWidth);

    return () => {
      window.addEventListener('hashchange', sendHash);
      window.removeEventListener('message', onUnparsedMessage);
    };
  }, [sendMessage, onMessageEvent]);

  return (
    <iframe
      srcDoc={srcDoc || undefined}
      src={url}
      title={title}
      ref={iframeRef}
      style={{ height }}
      className="w-100 b-none"
    />
  );
});
