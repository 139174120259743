import React, {
  useState, useEffect, useRef,
} from 'react';

import { IconClose } from 'components/Icon/Close';
import Link from 'components/Link';

import { useDispatch, useSelector } from 'react-redux';
import { loadMostPopularCuratedList, loadMostPopularRecipes } from 'redux/modules/navbar';
import styles from './styles.module.scss';

/**
 * Trending Nav
 * This component will display a list of popular stories, products, and recipes
 * from TODAY.
 * @returns {React.Element} The Trending Nav component
 */
function TrendingNav() {
  const [menuOpen, setMenuOpen] = useState(false);
  const navRef = useRef(null);
  const dispatch = useDispatch();
  /**
   * Toggles the menu open or closed
   */
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  /**
   * Sets the menuOpen state to false, closing the menu
   */
  const closeMenu = () => {
    setMenuOpen(false);
  };

  /**
   * Closes the menu when something outside of the menu is clicked
   * @param {Event} e The mouse down event
   */
  const handleClickOutside = (e) => {
    const { current } = navRef;
    if (!e // if no event data
      || !current // if no ref
      || current.contains(e.target) // if the click is within the ref element
    ) return;

    closeMenu();
  };
  /**
   * Handles the Escape keydown event
   * @param {KeyboardEvent} e The keydown event
   */
  const handleEscapeKey = (e) => {
    if (e.key === 'Escape') {
      closeMenu();
    }
  };

  /**
   * Attaches the event listeners for the trending nav
   * @function attachAccordionListeners
   * @description Attaches event listeners for click outside of the nav and keydown for the Escape key
   */
  const attachAccordionListeners = () => {
    window.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('keydown', handleEscapeKey);
  };

  /**
   * Removes the event listeners for the trending nav
   * @function removeAccordionListeners
   * @description Removes event listeners for click outside of the nav and keydown for the Escape key
   */
  const removeAccordionListeners = () => {
    window.removeEventListener('mousedown', handleClickOutside);
    window.removeEventListener('keydown', handleEscapeKey);
  };

  useEffect(() => {
    attachAccordionListeners();
    return () => removeAccordionListeners();
  }, [menuOpen]);


  useEffect(() => {
    dispatch(loadMostPopularCuratedList({ id: 'tdcl00055667799' }));
    dispatch(loadMostPopularCuratedList({ id: 'tdcl00077889900', type: 'shop' }));
    dispatch(loadMostPopularRecipes());
  }, []);

  const mostPopular = useSelector((state) => state.navbar.mostPopular);
  const { read, shop, recipe } = mostPopular || {};
  const arrayOfTrending = [read, shop, recipe];

  return (
    <div
      className={styles.trendingNavContainer}
      data-testid="trendingNavContainer"
    >
      <button
        className={styles.trendingNavButton}
        onClick={toggleMenu}
        data-testid="trendingNavButton"
        type="button"
      >
        🔥 Trending
      </button>
      {menuOpen
        && (
          <nav
            className={styles.trendingNav}
            ref={navRef}
            data-testid="trendingNavMenu"
          >
            <header className={styles.trendingHeader}>
              <button
                className={styles.closeButton}
                onClick={closeMenu}
                type="button"
                data-testid="trendingNavCloseButton"
              >
                <IconClose />
              </button>
            </header>
            <div className={styles.sectionsContainer}>

              {arrayOfTrending.map((obj) => (
                <section className={styles.trendingSection}>
                  <h4>{obj.title}</h4>
                  {obj.items.map((trendingItem) => (
                    <div className={styles.trendingOption}>
                      <div>🎀</div>
                      <Link
                        className={styles.trendingOptionLink}
                        href={trendingItem.url}
                      >
                        {trendingItem.headline}
                      </Link>
                    </div>
                  ))}
                </section>
              ))}

            </div>
          </nav>

        )}
    </div>
  );
}

export { TrendingNav };
