import React from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash.get';
import getConfig from 'next/config';

import { children as childrenPropType } from 'lib/CustomPropTypes';
import { isShellArticle as isShell } from 'lib/article';
import { isTelemundoVertical } from 'lib/vertical';
import { layout as servicesLayoutPropType } from 'lib/CustomPropTypes/services';
import { TVE_CANONICAL_ROUTE } from 'lib/tve';
import { usePrevious } from 'lib/Hooks/usePrevious';
import BTE from 'lib/BTE';
import { isElectionsTaxonomy } from 'lib/taxonomy';

import Ad from 'components/Ad';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { AlertBanner } from 'components/AlertBanner';
import { Marquee } from 'components/Marquee';
import ProgramGuide from 'components/ProgramGuide';

import ServiceHeader from '../Header';
import { ServiceFooter } from '../Footer';

import './styles.themed.scss';

/**
 *
 * @param {object} root0
 * @param {Article} root0.article
 * @param {object} root0.router
 * @param {object} root0.shared
 * @param {object} root0.front
 * @param {object} root0.recipe
 * @param {object} root1
 * @param {VerticalType} root1.vertical
 */
const mapStateToProps = ({
  article,
  router,
  shared,
  front,
  recipe,
}, { vertical }) => {
  const adsEnabled = get(article, ['content', 0, 'adsEnabled'], true);
  const ecommerceEnabled = get(article, ['content', 0, 'ecommerceEnabled'], false)
    || get(recipe, ['current', 'ecommerceEnabled'], false);
  const nativeAd = get(article, 'isNativeAd', false) || get(recipe, ['current', 'nativeAd'], false);
  const adsDisabledByCurator = get(front, 'curation.adsDisabled', false);
  const hasElections = isElectionsTaxonomy(article?.content?.[0]?.taxonomy?.primaryTopic?.slug);

  return {
    adsEnabled: adsEnabled && !ecommerceEnabled && !nativeAd,
    isShellArticle: isShell(article),
    currentPath: router.path,
    pageView: shared.pageView,
    vertical: shared.vertical || vertical,
    adsDisabledByCurator,
    isEmbedProgramGuide: front.isEmbedProgramGuide,
    isElectionsNavEnabled: hasElections,
  };
};

/**
 *
 * @param {object} root0
 * @param {boolean} root0.adsDisabledByCurator
 * @param {boolean} root0.adsEnabled
 * @param {string} root0.currentPath
 * @param {string} root0.pageRoute
 * @param {string} root0.pageView
 * @param {VerticalType} root0.vertical
 * @param {boolean} root0.forceHideBannerAd
 */
function getShouldShowBannerAd({
  adsDisabledByCurator,
  adsEnabled,
  currentPath,
  pageRoute,
  pageView,
  vertical,
  forceHideBannerAd,
}) {
  // Blanket disable for video and slideshow pages
  if (forceHideBannerAd || ['video', 'slideshow'].includes(pageView)) {
    return false;
  }
  // Disable on MSNBC TVE page
  if (vertical === 'msnbc' && currentPath.includes(`/${TVE_CANONICAL_ROUTE}`)) {
    return false;
  }

  // Disable on TODAY rebrand fronts
  if (vertical === 'today' && pageRoute === '/') {
    return false;
  }

  // [1] If a vertical has no key in `disableAd`, the bannerAd will be enabled for all pageViews
  // [2] An entry of `all` will disable the bannerAd on all pageViews for the given vertical
  // Examples:
  // Disable on Mach fronts and slideshows -- mach: ['front', 'slideshow']
  // Disable on all Mach page types -- mach: ['all']
  const disableAd = {
  };

  const disabledOnPages = disableAd[vertical];

  const isArticleWithAdsEnabled = pageView === 'article' && adsEnabled;
  const isAdsEnabledByCurator = !adsDisabledByCurator;
  const isFrontAndAdsEnabledByCurator = pageView === 'front' && isAdsEnabledByCurator;
  // recipes will always have ads enabled
  const isRecipeWithAdsEnabled = pageView === 'recipe' && adsEnabled;
  const showBannerAd = isArticleWithAdsEnabled
    || isFrontAndAdsEnabledByCurator
    || isRecipeWithAdsEnabled;

  // [1] For verticals without keys in `disableAd`
  if (typeof disabledOnPages === 'undefined' && showBannerAd) {
    return true;
  }

  // [2] For verticals marked `all` or with specific page types
  if (disabledOnPages && (disabledOnPages.includes('all') || disabledOnPages.includes(pageView))) {
    return false;
  }

  return showBannerAd;
}

/**
 * HeaderAndFooter component that renders the header and footer along with ads and other elements.
 *
 * @param {object} props - The properties passed to the component.
 * @param {boolean} props.adsDisabledByCurator - Flag indicating if ads are disabled by curator.
 * @param {boolean} props.adsEnabled - Flag indicating if ads are enabled.
 * @param {React.ReactNode} props.children - The children elements to be rendered.
 * @param {string} props.currentPath - The current path of the router.
 * @param {string} props.pageRoute - The route of the page.
 * @param {boolean} props.isEmbedProgramGuide - Flag indicating if the program guide is embedded.
 * @param {boolean} props.isShellArticle - Flag indicating if the article is a shell article.
 * @param {HFS_Layout} props.layout - The layout configuration for the header and footer.
 * @param {string} props.pageView - The view of the page.
 * @param {VerticalType} props.vertical - The vertical of the page.
 * @param {boolean} props.isNavbarSticky - Flag indicating if the navbar is sticky.
 * @param {React.ReactNode} props.contentAboveHeader - The content to be rendered above the header.
 * @param {boolean} props.isElectionsNavEnabled - Flag indicating if the elections navigation is enabled.
 * @returns {React.ReactNode} The rendered component.
 */
function HeaderAndFooter(props) {
  const {
    adsDisabledByCurator,
    adsEnabled,
    children,
    currentPath,
    pageRoute,
    isEmbedProgramGuide,
    isShellArticle,
    layout,
    pageView,
    vertical,
    isNavbarSticky,
    contentAboveHeader,
    isElectionsNavEnabled,
  } = props;

  const { publicRuntimeConfig: { TOP_BANNER_AD_REFRESH_INTERVAL } } = getConfig();

  const refreshIntervalConfigValue = parseInt(TOP_BANNER_AD_REFRESH_INTERVAL, 10);
  const bannerAdRefreshInterval = !Number.isNaN(refreshIntervalConfigValue)
    ? refreshIntervalConfigValue
    // using `undefined` here to allow the ad to fall back to the default refresh
    // interval set globally in `getEnigmaConfig`
    : undefined;

  const [isTopBannerAdUnstuck, setIsTopBannerAdUnstuck] = React.useState(false);
  const [isAdInitialRenderComplete, setIsAdInitialRenderComplete] = React.useState(false);
  const [isScrolledToTop, setIsScrolledToTop] = React.useState(true);
  const [timeLeftForStickyAd, setTimeLeftForStickyAd] = React.useState('0s');

  // using a ref here for `isAdRendered` rather than the state `isAdInitialRenderComplete`
  // declared above, in order to reference it within a timeout set in useEffect below
  const isAdRendered = React.useRef(false);
  const adRenderTimeoutId = React.useRef();
  const adStickyTimeoutId = React.useRef();
  const mpsAd = React.useRef();

  const isNewsLandingPage = vertical === 'news' && pageRoute === '/';
  const AD_RENDER_TIMEOUT = isNewsLandingPage ? 6 * 1000 : 3 * 1000;
  const AD_STICKY_TIMEOUT = isNewsLandingPage ? 5 * 1000 : 4 * 1000;
  let timerEnd;
  let timesScrolledToTop = 0;
  const previousPageView = usePrevious(pageView);

  // I don't think the below is needed since we no longer "transport" from video/slideshow->fronts
  // but leaving it here for now.
  // When transporting from a video or slideshow to a front, force the banner ad to be hidden
  const forceHideBannerAd = ['video', 'slideshow'].includes(previousPageView)
    && pageView === 'front';

  React.useEffect(() => {
    // when component first mounts, wait maximum of AD_RENDER_TIMEOUT time,
    // then if the ad render event hasn't fired by then, go ahead and un-stick
    // the banner ad
    adRenderTimeoutId.current = setTimeout(() => {
      if (!isAdRendered.current) {
        // if the ad request hasn't been initiated by now, something is probably
        // wrong. go ahead and unstick so we don't have a permanently `sticky` ad
        setIsTopBannerAdUnstuck(true);
      }
    }, AD_RENDER_TIMEOUT);

    return () => {
      clearTimeout(adRenderTimeoutId.current);
      // clear the timeout for the sticky timeout on unmount as well, just in case
      // we are unmounting before that completes
      clearTimeout(adStickyTimeoutId.current);
    };
  }, []);

  const getTimeLeftForStickyTimeout = () => {
    const secondsToEnd = Math.ceil((timerEnd - new Date().getTime()) / 1000 - 1.2);
    return !Number.isNaN(secondsToEnd) && secondsToEnd > 0 ? `${secondsToEnd}s` : '0s';
  };

  const bannerAdHandler = React.useCallback((handlerAd) => {
    mpsAd.current = mpsAd.current || handlerAd;
    mpsAd.current.onRender(() => {
      isAdRendered.current = true;
      setIsAdInitialRenderComplete(true);
      setIsTopBannerAdUnstuck(false);

      // when the ad first renders, clear the adRenderTimeoutId timeout, and show
      // the ad (keep it sticky) for another AD_STICKY_TIMEOUT amount of time
      clearTimeout(adRenderTimeoutId.current);
      adStickyTimeoutId.current = setTimeout(() => {
        setIsTopBannerAdUnstuck(true);
      }, AD_STICKY_TIMEOUT);
      timerEnd = new Date().getTime() + AD_STICKY_TIMEOUT;
    });
  }, []);

  const getTopBar = () => {
    if (contentAboveHeader) {
      return contentAboveHeader;
    }

    if (!isShellArticle) {
      return <Marquee marquees={layout.marquees} isNewsLandingPage={isNewsLandingPage} />;
    }

    return null;
  };

  React.useEffect(() => {
    function handleScroll(scrollDepth) {
      const scrollDepthThreshold = layout?.marquees?.length > 0 ? 165 : 0;
      if (scrollDepth <= scrollDepthThreshold) {
        setIsScrolledToTop(true);
        if (isAdInitialRenderComplete) {
          timesScrolledToTop += 1;
        }
      } else {
        setIsScrolledToTop(false);
      }

      if (timesScrolledToTop > 0) {
        setTimeLeftForStickyAd(getTimeLeftForStickyTimeout());
      }
    }

    handleScroll(window.scrollY);
    BTE.on('scroll', handleScroll, 'debounce');

    return () => {
      BTE.remove('scroll', handleScroll, 'debounce');
    };
  }, [BTE, isAdInitialRenderComplete]);

  const shouldShowBannerAd = getShouldShowBannerAd({
    adsDisabledByCurator,
    adsEnabled,
    currentPath,
    pageView,
    vertical,
    forceHideBannerAd,
    pageRoute,
  });

  const bannerAdClasses = classNames(
    'header-and-footer--banner-ad',
    'ad-container topbannerAd',
    {
      'landing-header-footer-banner-ad': isNewsLandingPage,
      'header-and-footer--static': isTopBannerAdUnstuck,
      'landing-slide-animation': isNewsLandingPage && !isTopBannerAdUnstuck,
      'animation-delay': isNewsLandingPage && isAdInitialRenderComplete,
      'hide-animation': isNewsLandingPage && isScrolledToTop && isAdInitialRenderComplete,
      'landing-animation-scrolled': isNewsLandingPage && !isScrolledToTop && isAdInitialRenderComplete,
      // The header footer service listens for a class change
      // to recalc its position. The below 👇 classes serve to trigger that
      isAdInitialRenderComplete,
      isScrolledToTop,
    },
  );

  const getHeaderLayout = () => (isNewsLandingPage ? (
    <>
      <ErrorBoundary>
        {/* Alert for IE11 */}
        <AlertBanner alertText={i18next.t('IE11 ALERT')} />
      </ErrorBoundary>

      <ErrorBoundary>
        {getTopBar()}
      </ErrorBoundary>

      <ErrorBoundary>
        <ServiceHeader
          adsEnabled={adsEnabled}
          currentPath={currentPath}
          header={layout.header}
          pageView={pageView}
          vertical={vertical}
          isNavbarSticky={isNavbarSticky}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        {shouldShowBannerAd && (
          <>
            <div
              className={bannerAdClasses}
              style={{
                ...(timeLeftForStickyAd !== '0s' && { animationDelay: timeLeftForStickyAd }),
              }}
            >
              <Ad
                handler={bannerAdHandler}
                slot="topbanner"
                refreshInterval={bannerAdRefreshInterval}
              />
              <p className="ad-placeholder">Advertisement</p>
            </div>
          </>
        )}
      </ErrorBoundary>
    </>
  ) : (
    <>
      <ErrorBoundary>
        {shouldShowBannerAd && (
          <div className={bannerAdClasses}>
            <Ad
              handler={bannerAdHandler}
              slot="topbanner"
              refreshInterval={bannerAdRefreshInterval}
            />
          </div>
        )}
      </ErrorBoundary>

      <ErrorBoundary>
        {/* Alert for IE11 */}
        <AlertBanner alertText={i18next.t('IE11 ALERT')} />
      </ErrorBoundary>

      <ErrorBoundary>
        {getTopBar()}
      </ErrorBoundary>

      <ErrorBoundary>
        <ServiceHeader
          adsEnabled={adsEnabled}
          currentPath={currentPath}
          header={layout.header}
          pageView={pageView}
          vertical={vertical}
          isNavbarSticky={isNavbarSticky}
          isElectionsNavEnabled={isElectionsNavEnabled}
        />
      </ErrorBoundary>
    </>
  ));

  return (
    <>
      <ErrorBoundary>
        {getHeaderLayout()}
      </ErrorBoundary>
      {children}

      {isTelemundoVertical(vertical) && !isEmbedProgramGuide && currentPath !== '/noticias/noticias-telemundo-ahora' && (
        <ErrorBoundary>
          <ProgramGuide url="https://embed.telemundo.com/program-guide/index.html" />
        </ErrorBoundary>
      )}

      <ErrorBoundary>
        <ServiceFooter footer={layout.footer} />
      </ErrorBoundary>
    </>
  );
}

HeaderAndFooter.propTypes = {
  adsDisabledByCurator: PropTypes.bool,
  adsEnabled: PropTypes.bool,
  children: childrenPropType,
  currentPath: PropTypes.string.isRequired,
  pageRoute: PropTypes.string,
  contentAboveHeader: PropTypes.any,
  isEmbedProgramGuide: PropTypes.bool,
  isShellArticle: PropTypes.bool,
  layout: servicesLayoutPropType,
  pageView: PropTypes.string,
  vertical: PropTypes.string.isRequired,
  isNavbarSticky: PropTypes.bool,
  isElectionsNavEnabled: PropTypes.bool,
};

HeaderAndFooter.defaultProps = {
  adsDisabledByCurator: false,
  adsEnabled: true,
  children: null,
  pageRoute: '',
  isEmbedProgramGuide: false,
  isShellArticle: false,
  contentAboveHeader: null,
  layout: {
    header: {},
    footer: {},
    marquees: [],
  },
  pageView: null,
  isNavbarSticky: true,
  isElectionsNavEnabled: false,
};

const WrappedHeaderAndFooter = connect(mapStateToProps)(HeaderAndFooter);

export { WrappedHeaderAndFooter as HeaderAndFooter };
